import React, { useEffect, useState, useMemo, lazy } from 'react';
import './App.css';
import Navbar from './component/Navbar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './component/home';
import { authCheck, getInfoUser } from './services/api';
import Register from './component/Register';
import Login from './component/Login';
import LoginPass from './component/loginPass';
import ForgotPassword from './component/ForgotPassword';
import ChangePass from './component/ChangePass';
import VerifiedEmail from './component/VerifiedEmail';
import NotVerifiedEmail from './component/NotVerifiedEmail';
import AlertMessage from './component/modules/AlertMessage';
import { useTranslation } from 'react-i18next';
import NotFound from './component/NotFound';
import ScrollToTop from './component/ScrollToTop';
import ArrowTop from './component/ArrowTop';
import CryptoContextProvider from './context/CryptoContextProvider';
import Blog from './component/Blog';

import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { getDesignTokens } from './theme/theme';
import Loading from './component/Loading';
import AllBookTickerContext from './context/AllBookTickerContext.js';

const Panel = lazy(() => import('./component/Panel'))
const Wallet = lazy(() => import('./component/Wallet'))
const PaymentError = lazy(() => import('./component/PaymentError'))
const PaymentSuccessfully = lazy(() => import('./component/PaymentSuccessfully'))
const Orders = lazy(() => import('./component/Orders'))
const Terms = lazy(() => import('./component/Terms'))
const ContactUs = lazy(() => import('./component/ContactUs'))
const AboutUs = lazy(() => import('./component/AboutUs'))
const BlogSearch = lazy(() => import('./component/BlogSearch'))
const News = lazy(() => import('./component/News'))
const NewsAll = lazy(() => import('./component/NewsAll'))
const Help = lazy(() => import('./component/Help'))
const Market = lazy(() => import('./component/Market'))
const Loan = lazy(() => import('./component/Loan'))
const Stacking = lazy(() => import('./component/Stacking'))
const Exchange = lazy(() => import('./component/Exchange'))
const Prize = lazy(() => import('./component/Prize'))

const BlockChain = lazy(() => import('./component/data/Blog/BlockChain'))
const Bitcoin = lazy(() => import('./component/data/Blog/Bitcoin'))
const Ethereum = lazy(() => import('./component/data/Blog/Ethereum'))
const BestSite = lazy(() => import('./component/data/Blog/BestSite'))
const Doge = lazy(() => import('./component/data/Blog/Doge'))
const Monero = lazy(() => import('./component/data/Blog/Monero'))
const Nft = lazy(() => import('./component/data/Blog/Nft'))
const EthereumClassic = lazy(() => import('./component/data/Blog/EthereumClassic'))
const Dao = lazy(() => import('./component/data/Blog/Dao'))
const Ape = lazy(() => import('./component/data/Blog/Ape'))
const Slp = lazy(() => import('./component/data/Blog/Slp'))
const Miners = lazy(() => import('./component/data/Blog/Miners'))
const LiteCoin = lazy(() => import('./component/data/Blog/LiteCoin'))
const Nem = lazy(() => import('./component/data/Blog/Nem'))
const Xlm = lazy(() => import('./component/data/Blog/Xlm'))
const Matic = lazy(() => import('./component/data/Blog/Matic'))
const Bnb = lazy(() => import('./component/data/Blog/Bnb'))
const Icp = lazy(() => import('./component/data/Blog/Icp'))
const IndicatorsOscillators = lazy(() => import('./component/data/Blog/IndicatorsOscillators'))
const Xtz = lazy(() => import('./component/data/Blog/Xtz'))
const Egld = lazy(() => import('./component/data/Blog/Egld'))
const Bch = lazy(() => import('./component/data/Blog/Bch'))
const Hot = lazy(() => import('./component/data/Blog/Hot'))
const Ftm = lazy(() => import('./component/data/Blog/Ftm'))


const BTC = lazy(() => import('./component/data/DetailCrypto/BTC'))
const ETH = lazy(() => import('./component/data/DetailCrypto/ETH'))
const ETC = lazy(() => import('./component/data/DetailCrypto/ETC'))
const BNB = lazy(() => import('./component/data/DetailCrypto/BNB'))
const TRX = lazy(() => import('./component/data/DetailCrypto/TRX'))
const XRP = lazy(() => import('./component/data/DetailCrypto/XRP'))
const ADA = lazy(() => import('./component/data/DetailCrypto/ADA'))
const APE = lazy(() => import('./component/data/DetailCrypto/APE'))
const DOGE = lazy(() => import('./component/data/DetailCrypto/DOGE'))
const SHIB = lazy(() => import('./component/data/DetailCrypto/SHIB'))
const SLP = lazy(() => import('./component/data/DetailCrypto/SLP'))
const DASH = lazy(() => import('./component/data/DetailCrypto/DASH'))
const UNI = lazy(() => import('./component/data/DetailCrypto/UNI'))
const MKR = lazy(() => import('./component/data/DetailCrypto/MKR'))
const AAVE = lazy(() => import('./component/data/DetailCrypto/AAVE'))
const XMR = lazy(() => import('./component/data/DetailCrypto/XMR'))
const LTC = lazy(() => import('./component/data/DetailCrypto/LTC'))
const XEM = lazy(() => import('./component/data/DetailCrypto/XEM'))
const PUNDIX = lazy(() => import('./component/data/DetailCrypto/PUNDIX'))
const ICP = lazy(() => import('./component/data/DetailCrypto/ICP'))
const AXS = lazy(() => import('./component/data/DetailCrypto/AXS'))
const POL = lazy(() => import('./component/data/DetailCrypto/POL'))
const XTZ = lazy(() => import('./component/data/DetailCrypto/XTZ'))
const NEO = lazy(() => import('./component/data/DetailCrypto/NEO'))
const BCH = lazy(() => import('./component/data/DetailCrypto/BCH'))
const USDT = lazy(() => import('./component/data/DetailCrypto/USDT'))
const ALGO = lazy(() => import('./component/data/DetailCrypto/ALGO'))
const EOS = lazy(() => import('./component/data/DetailCrypto/EOS'))
const AVAX = lazy(() => import('./component/data/DetailCrypto/AVAX'))
const NEAR = lazy(() => import('./component/data/DetailCrypto/NEAR'))
const ARB = lazy(() => import('./component/data/DetailCrypto/ARB'))
const SOL = lazy(() => import('./component/data/DetailCrypto/SOL'))
const CHZ = lazy(() => import('./component/data/DetailCrypto/CHZ'))
const RARE = lazy(() => import('./component/data/DetailCrypto/RARE'))
const SXP = lazy(() => import('./component/data/DetailCrypto/SXP'))
const XLM = lazy(() => import('./component/data/DetailCrypto/XLM'))
const ATOM = lazy(() => import('./component/data/DetailCrypto/ATOM'))
const CELO = lazy(() => import('./component/data/DetailCrypto/CELO'))
const FTM = lazy(() => import('./component/data/DetailCrypto/FTM'))
const BTCUP = lazy(() => import('./component/data/DetailCrypto/BTCUP'))
const ETHUP = lazy(() => import('./component/data/DetailCrypto/ETHUP'))
const BNBUP = lazy(() => import('./component/data/DetailCrypto/BNBUP'))
const BTCDOWN = lazy(() => import('./component/data/DetailCrypto/BTCDOWN'))
const ETHDOWN = lazy(() => import('./component/data/DetailCrypto/ETHDOWN'))
const BNBDOWN = lazy(() => import('./component/data/DetailCrypto/BNBDOWN'))
const USDC = lazy(() => import('./component/data/DetailCrypto/USDC'))
const TUSD = lazy(() => import('./component/data/DetailCrypto/TUSD'))
const QTUM = lazy(() => import('./component/data/DetailCrypto/QTUM'))
const IOTA = lazy(() => import('./component/data/DetailCrypto/IOTA'))
const LINK = lazy(() => import('./component/data/DetailCrypto/LINK'))
const BONK = lazy(() => import('./component/data/DetailCrypto/BONK'))
const BLUR = lazy(() => import('./component/data/DetailCrypto/BLUR'))
const JTO = lazy(() => import('./component/data/DetailCrypto/JTO'))
const DOT = lazy(() => import('./component/data/DetailCrypto/DOT'))
const PEPE = lazy(() => import('./component/data/DetailCrypto/PEPE'))
const STORJ = lazy(() => import('./component/data/DetailCrypto/STORJ'))
const FIL = lazy(() => import('./component/data/DetailCrypto/FIL'))
const SIA = lazy(() => import('./component/data/DetailCrypto/SIA'))
const GTC = lazy(() => import('./component/data/DetailCrypto/GTC'))
const COMP = lazy(() => import('./component/data/DetailCrypto/COMP'))
const DYDX = lazy(() => import('./component/data/DetailCrypto/DYDX'))
const ONT = lazy(() => import('./component/data/DetailCrypto/ONT'))
const ONG = lazy(() => import('./component/data/DetailCrypto/ONG'))
const HOT = lazy(() => import('./component/data/DetailCrypto/HOT'))
const YFI = lazy(() => import('./component/data/DetailCrypto/YFI'))
const SC = lazy(() => import('./component/data/DetailCrypto/SC'))
const NOT = lazy(() => import('./component/data/DetailCrypto/NOT'))
const FDUSD = lazy(() => import('./component/data/DetailCrypto/FDUSD'))
const VET = lazy(() => import('./component/data/DetailCrypto/Vet.js'))
const BTTC = lazy(() => import('./component/data/DetailCrypto/BTTC.js'))
const XEC = lazy(() => import("./component/data/DetailCrypto/XEC.js"))
const CRV = lazy(() => import("./component/data/DetailCrypto/CRV.js"))
const JST = lazy(() => import("./component/data/DetailCrypto/JST.js"))
const PAXG = lazy(() => import("./component/data/DetailCrypto/PAXG.js"))
const DGB = lazy(() => import("./component/data/DetailCrypto/DGB.js"))


const News1 = lazy(() => import('./component/data/News/News1'))
const News2 = lazy(() => import('./component/data/News/News2'))
const News3 = lazy(() => import('./component/data/News/News3'))
const News4 = lazy(() => import('./component/data/News/News4'))
const News5 = lazy(() => import('./component/data/News/News5'))
const News6 = lazy(() => import('./component/data/News/News6'))
const News7 = lazy(() => import('./component/data/News/News7'))
const News8 = lazy(() => import('./component/data/News/News8'))
const News9 = lazy(() => import('./component/data/News/News9'))
const News10 = lazy(() => import('./component/data/News/News10'))
const News11 = lazy(() => import('./component/data/News/News11'))
const News12 = lazy(() => import('./component/data/News/News12'))
const News13 = lazy(() => import('./component/data/News/News13'))
const News14 = lazy(() => import('./component/data/News/News14'))
const News15 = lazy(() => import('./component/data/News/News15'))
const News16 = lazy(() => import('./component/data/News/News16'))
const News17 = lazy(() => import('./component/data/News/News17'))
const News18 = lazy(() => import('./component/data/News/News18'))
const News19 = lazy(() => import('./component/data/News/News19'))
const News20 = lazy(() => import('./component/data/News/News20'))
const News21 = lazy(() => import('./component/data/News/News21'))
const News22 = lazy(() => import('./component/data/News/News22'))
const News23 = lazy(() => import('./component/data/News/News23'))
const News24 = lazy(() => import('./component/data/News/News24'))
const News25 = lazy(() => import('./component/data/News/News25'))
const News26 = lazy(() => import('./component/data/News/News26'))
const News27 = lazy(() => import('./component/data/News/News27'))
const News28 = lazy(() => import('./component/data/News/News28'))
const News29 = lazy(() => import('./component/data/News/News29'))
const News30 = lazy(() => import('./component/data/News/News30'))
const News31 = lazy(() => import('./component/data/News/News31'))
const News32 = lazy(() => import('./component/data/News/News32'))
const News33 = lazy(() => import('./component/data/News/News33'))
const News34 = lazy(() => import('./component/data/News/News34'))
const News35 = lazy(() => import('./component/data/News/News35'))
const News36 = lazy(() => import('./component/data/News/News36'))
const News37 = lazy(() => import('./component/data/News/News37'))
const News38 = lazy(() => import('./component/data/News/News38'))
const News39 = lazy(() => import('./component/data/News/News39'))
const News40 = lazy(() => import('./component/data/News/News40'))
const News41 = lazy(() => import('./component/data/News/News41'))
const News42 = lazy(() => import('./component/data/News/News42'))
const News43 = lazy(() => import('./component/data/News/News43'))
const News44 = lazy(() => import('./component/data/News/News44'))
const News45 = lazy(() => import('./component/data/News/News45'))
const News46 = lazy(() => import('./component/data/News/News46'))
const News47 = lazy(() => import('./component/data/News/News47'))
const News48 = lazy(() => import('./component/data/News/News48'))

function App() {
  const [auth, setAuth] = useState(false);
  const [infoUser, setInfoUser] = useState(null);
  const [alert, setAlert] = useState({ alertCheck: false, status: null, message: "", severity: "" });
  const [t, i18n] = useTranslation();
  const [toggleDarkMode, setToggleDarkMode] = useState(false);
  const [mode, setMode] = useState('light');


  useEffect(() => {
    toggleColorMode()
  }, [toggleDarkMode])


  const toggleDarkTheme = () => {
    setToggleDarkMode(!toggleDarkMode);
  };

  const toggleColorMode = () => {
    setMode(toggleDarkMode ? "dark" : "light");
  };

  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  useEffect(() => {
    getCheckAuth();
    const updateAuthCheackInterval = setInterval(() => {
      getCheckAuth();
    }, 1800000);
    return () => {
      clearInterval(updateAuthCheackInterval);
    };
  }, [auth]);

  const getCheckAuth = () => {
    authCheck().then((res) => {
      setAuth(res);
      if (res) {
        getInfoUser().then((res) => { localStorage.setItem("CryptoInfoUser", JSON.stringify(res)); setInfoUser(res) })
      }
    }).catch((err) => {
      if (err.code === "ERR_NETWORK") {
        setAlert({ alertCheck: true, message: t("networkNotconnected"), severity: "error" })
      }
    });

  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    return setAlert({ alertCheck: false, message: "", severity: alert.severity })
  };


  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AllBookTickerContext>
          <CryptoContextProvider>
            <Navbar authCheck={auth} infoUser={infoUser} darkMode={toggleDarkMode} onchangeDarkMode={toggleDarkTheme} />
            <ScrollToTop />
            <ArrowTop />
            <Routes>
              <Route path='/' element={<Home authCheck={auth} infoUser={infoUser} />} exact />
              <Route path='/Register' element={<Register />} />
              <Route path='/Register/:value' element={<Register />} />
              <Route path='/Login' element={<Login />} />
              <Route path='/Login/:email' element={<LoginPass />} />
              <Route path='/ForgotPassword/:email' element={<ForgotPassword />} />
              <Route path='/ChangePass/:email' element={<ChangePass />} />
              <Route path='/VerifiedEmail' element={<VerifiedEmail />} />
              <Route path='/NotVerifiedEmail' element={<NotVerifiedEmail />} />
              <Route path='/Terms' element={<React.Suspense><Terms /></React.Suspense>} />
              <Route path='/AboutUs' element={<React.Suspense><AboutUs /></React.Suspense>} />
              <Route path='/ContactUs' element={<React.Suspense><ContactUs /></React.Suspense>} />
              <Route path='/Guide' element={<React.Suspense><Help /></React.Suspense>} />
              <Route path='/Market' element={<React.Suspense><Market authCheck={auth} /></React.Suspense>} />
              <Route path='/Details/BTC' element={<React.Suspense><BTC /></React.Suspense>} />
              <Route path='/Details/ETH' element={<React.Suspense><ETH /></React.Suspense>} />
              <Route path='/Details/ETC' element={<React.Suspense><ETC /></React.Suspense>} />
              <Route path='/Details/BNB' element={<React.Suspense><BNB /></React.Suspense>} />
              <Route path='/Details/TRX' element={<React.Suspense><TRX /></React.Suspense>} />
              <Route path='/Details/XRP' element={<React.Suspense><XRP /></React.Suspense>} />
              <Route path='/Details/ADA' element={<React.Suspense><ADA /></React.Suspense>} />
              <Route path='/Details/APE' element={<React.Suspense><APE /></React.Suspense>} />
              <Route path='/Details/DOGE' element={<React.Suspense><DOGE /></React.Suspense>} />
              <Route path='/Details/SHIB' element={<React.Suspense><SHIB /></React.Suspense>} />
              <Route path='/Details/SLP' element={<React.Suspense><SLP /></React.Suspense>} />
              <Route path='/Details/DASH' element={<React.Suspense><DASH /></React.Suspense>} />
              <Route path='/Details/UNI' element={<React.Suspense><UNI /></React.Suspense>} />
              <Route path='/Details/MKR' element={<React.Suspense><MKR /></React.Suspense>} />
              <Route path='/Details/AAVE' element={<React.Suspense><AAVE /></React.Suspense>} />
              <Route path='/Details/XMR' element={<React.Suspense><XMR /></React.Suspense>} />
              <Route path='/Details/LTC' element={<React.Suspense><LTC /></React.Suspense>} />
              <Route path='/Details/XEM' element={<React.Suspense><XEM /></React.Suspense>} />
              <Route path='/Details/XLM' element={<React.Suspense><XLM /></React.Suspense>} />
              <Route path='/Details/PUNDIX' element={<React.Suspense><PUNDIX /></React.Suspense>} />
              <Route path='/Details/ICP' element={<React.Suspense><ICP /></React.Suspense>} />
              <Route path='/Details/AXS' element={<React.Suspense><AXS /></React.Suspense>} />
              <Route path='/Details/POL' element={<React.Suspense><POL /></React.Suspense>} />
              <Route path='/Details/XTZ' element={<React.Suspense><XTZ /></React.Suspense>} />
              <Route path='/Details/NEO' element={<React.Suspense><NEO /></React.Suspense>} />
              <Route path='/Details/BCH' element={<React.Suspense><BCH /></React.Suspense>} />
              <Route path='/Details/USDT' element={<React.Suspense><USDT /></React.Suspense>} />
              <Route path='/Details/ALGO' element={<React.Suspense><ALGO /></React.Suspense>} />
              <Route path='/Details/EOS' element={<React.Suspense><EOS /></React.Suspense>} />
              <Route path='/Details/AVAX' element={<React.Suspense><AVAX /></React.Suspense>} />
              <Route path='/Details/NEAR' element={<React.Suspense><NEAR /></React.Suspense>} />
              <Route path='/Details/ARB' element={<React.Suspense><ARB /></React.Suspense>} />
              <Route path='/Details/SOL' element={<React.Suspense><SOL /></React.Suspense>} />
              <Route path='/Details/CHZ' element={<React.Suspense><CHZ /></React.Suspense>} />
              <Route path='/Details/RARE' element={<React.Suspense><RARE /></React.Suspense>} />
              <Route path='/Details/SXP' element={<React.Suspense><SXP /></React.Suspense>} />
              <Route path='/Details/ATOM' element={<React.Suspense><ATOM /></React.Suspense>} />
              <Route path='/Details/CELO' element={<React.Suspense><CELO /></React.Suspense>} />
              <Route path='/Details/FTM' element={<React.Suspense><FTM /></React.Suspense>} />
              <Route path='/Details/BTCUP' element={<React.Suspense><BTCUP /></React.Suspense>} />
              <Route path='/Details/ETHUP' element={<React.Suspense><ETHUP /></React.Suspense>} />
              <Route path='/Details/BNBUP' element={<React.Suspense><BNBUP /></React.Suspense>} />
              <Route path='/Details/BTCDOWN' element={<React.Suspense><BTCDOWN /></React.Suspense>} />
              <Route path='/Details/ETHDOWN' element={<React.Suspense><ETHDOWN /></React.Suspense>} />
              <Route path='/Details/BNBDOWN' element={<React.Suspense><BNBDOWN /></React.Suspense>} />
              <Route path='/Details/USDC' element={<React.Suspense><USDC /></React.Suspense>} />
              <Route path='/Details/TUSD' element={<React.Suspense><TUSD /></React.Suspense>} />
              <Route path='/Details/QTUM' element={<React.Suspense><QTUM /></React.Suspense>} />
              <Route path='/Details/IOTA' element={<React.Suspense><IOTA /></React.Suspense>} />
              <Route path='/Details/LINK' element={<React.Suspense><LINK /></React.Suspense>} />
              <Route path='/Details/BONK' element={<React.Suspense><BONK /></React.Suspense>} />
              <Route path='/Details/BLUR' element={<React.Suspense><BLUR /></React.Suspense>} />
              <Route path='/Details/JTO' element={<React.Suspense><JTO /></React.Suspense>} />
              <Route path='/Details/DOT' element={<React.Suspense><DOT /></React.Suspense>} />
              <Route path='/Details/PEPE' element={<React.Suspense><PEPE /></React.Suspense>} />
              <Route path='/Details/STORJ' element={<React.Suspense><STORJ /></React.Suspense>} />
              <Route path='/Details/FIL' element={<React.Suspense><FIL /></React.Suspense>} />
              <Route path='/Details/SIA' element={<React.Suspense><SIA /></React.Suspense>} />
              <Route path='/Details/GTC' element={<React.Suspense><GTC /></React.Suspense>} />
              <Route path='/Details/COMP' element={<React.Suspense><COMP /></React.Suspense>} />
              <Route path='/Details/DYDX' element={<React.Suspense><DYDX /></React.Suspense>} />
              <Route path='/Details/ONT' element={<React.Suspense><ONT /></React.Suspense>} />
              <Route path='/Details/ONG' element={<React.Suspense><ONG /></React.Suspense>} />
              <Route path='/Details/HOT' element={<React.Suspense><HOT /></React.Suspense>} />
              <Route path='/Details/YFI' element={<React.Suspense><YFI /></React.Suspense>} />
              <Route path='/Details/SC' element={<React.Suspense><SC /></React.Suspense>} />
              <Route path='/Details/NOT' element={<React.Suspense><NOT /></React.Suspense>} />
              <Route path='/Details/FDUSD' element={<React.Suspense><FDUSD /></React.Suspense>} />
              <Route path='/Details/VET' element={<React.Suspense><VET /></React.Suspense>} />
              <Route path='/Details/BTTC' element={<React.Suspense><BTTC /></React.Suspense>} />
              <Route path='/Details/XEC' element={<React.Suspense><XEC /></React.Suspense>} />
              <Route path='/Details/CRV' element={<React.Suspense><CRV /></React.Suspense>} />
              <Route path='/Details/JST' element={<React.Suspense><JST /></React.Suspense>} />
              <Route path='/Details/PAXG' element={<React.Suspense><PAXG /></React.Suspense>} />
              <Route path='/Details/DGB' element={<React.Suspense><DGB /></React.Suspense>} />

              <Route path='/Exchange/:baseAsset/:quoteAsset' element={<React.Suspense fallback={<Loading />}><Exchange authCheck={auth} /></React.Suspense>} />
              <Route path='/Details/BLUR' element={<React.Suspense><BLUR /></React.Suspense>} />
              <Route path='/News' element={<React.Suspense><News /></React.Suspense>} />
              <Route path='/News/:value' element={<React.Suspense><NewsAll /></React.Suspense>} />
              <Route path='/Panel/:value' element={<React.Suspense><Panel authCheck={auth} /></React.Suspense>} />
              <Route path='/Orders/:value' element={<React.Suspense><Orders infoUser={infoUser} authCheck={auth} /></React.Suspense>} />
              <Route path='/Loan' element={<React.Suspense><Loan authCheck={auth} /></React.Suspense>} />
              <Route path='/Earn' element={<React.Suspense><Stacking authCheck={auth} /></React.Suspense>} />
              <Route path='/Prize' element={<React.Suspense><Prize authCheck={auth} /></React.Suspense>} />
              <Route path='/paymentError' element={<React.Suspense><PaymentError /></React.Suspense>} />
              <Route path='/paymentSuccessfully' element={<React.Suspense><PaymentSuccessfully /></React.Suspense>} />
              <Route path='/Blog' element={<Blog authCheck={auth} />} />
              <Route path='/Wallet/:value' element={<React.Suspense><Wallet authCheck={auth} infoUser={infoUser} /></React.Suspense>} />
              <Route path='/Wallet/:value/:select' element={<React.Suspense><Wallet authCheck={auth} infoUser={infoUser} /></React.Suspense>} />
              <Route path='/Wallet/:value/:select/:coin' element={<React.Suspense><Wallet authCheck={auth} infoUser={infoUser} /></React.Suspense>} />
              <Route path='/Blog/:value' element={<React.Suspense><BlogSearch authCheck={auth} /></React.Suspense>} />
              <Route path='/Blog/What-Is-Blockchain' element={<React.Suspense><BlockChain /></React.Suspense>} />
              <Route path='/Blog/What-Is-Bitcoin' element={<React.Suspense><Bitcoin /></React.Suspense>} />
              <Route path='/Blog/What-Is-Ethereum' element={<React.Suspense><Ethereum /></React.Suspense>} />
              <Route path='/Blog/The-Best-Sites-To-Get-Information-From-Cryptocurrencies' element={<React.Suspense><BestSite /></React.Suspense>} />
              <Route path='/Blog/What-Is-DOGE-Coin' element={<React.Suspense><Doge /></React.Suspense>} />
              <Route path='/Blog/What-Is-Monero' element={<React.Suspense><Monero /></React.Suspense>} />
              <Route path='/Blog/What-Is-NFT' element={<React.Suspense><Nft /></React.Suspense>} />
              <Route path='/Blog/What-Is-Ethereum-Classic' element={<React.Suspense><EthereumClassic /></React.Suspense>} />
              <Route path='/Blog/What-Is-DAO' element={<React.Suspense><Dao /></React.Suspense>} />
              <Route path='/Blog/What-Is-ApeCoin' element={<React.Suspense><Ape /></React.Suspense>} />
              <Route path='/Blog/What-Is-SLP' element={<React.Suspense><Slp /></React.Suspense>} />
              <Route path='/Blog/Introduce-Some-Crypto-Miners' element={<React.Suspense><Miners /></React.Suspense>} />
              <Route path='/Blog/What-Is-Lite-Coin' element={<React.Suspense><LiteCoin /></React.Suspense>} />
              <Route path='/Blog/What-Is-NEM' element={<React.Suspense><Nem /></React.Suspense>} />
              <Route path='/Blog/What-Is-XLM' element={<React.Suspense><Xlm /></React.Suspense>} />
              <Route path='/Blog/What-Is-MATIC' element={<React.Suspense><Matic /></React.Suspense>} />
              <Route path='/Blog/What-Is-BNB' element={<React.Suspense><Bnb /></React.Suspense>} />
              <Route path='/Blog/What-Is-ICP' element={<React.Suspense><Icp /></React.Suspense>} />
              <Route path='/Blog/Introduce-10-Technical-Indicators-And-Oscillators' element={<React.Suspense><IndicatorsOscillators /></React.Suspense>} />
              <Route path='/Blog/What-Is-XTZ' element={<React.Suspense><Xtz /></React.Suspense>} />
              <Route path='/Blog/What-Is-EGLD' element={<React.Suspense><Egld /></React.Suspense>} />
              <Route path='/Blog/What-Is-BCH' element={<React.Suspense><Bch /></React.Suspense>} />
              <Route path='/Blog/What-Is-HOT' element={<React.Suspense><Hot /></React.Suspense>} />
              <Route path='/Blog/What-Is-FTM' element={<React.Suspense><Ftm /></React.Suspense>} />

              <Route path='/News/Bybit-Users-Have-To-KYC' element={<React.Suspense><News1 /></React.Suspense>} />
              <Route path='/News/The-Fake-News-From-ETF-Made-Bitcoin-Over-30K' element={<React.Suspense><News2 /></React.Suspense>} />
              <Route path='/News/Increase-Deposit-Rial-In-Iranian-Crypto-Exchanges' element={<React.Suspense><News3 /></React.Suspense>} />
              <Route path='/News/Digital-Rial-Draft-Is-Ready-To-Release' element={<React.Suspense><News4 /></React.Suspense>} />
              <Route path='/News/Summoning-Drick-Coin-Scam-Project-To-The-Curt' element={<React.Suspense><News5 /></React.Suspense>} />
              <Route path='/News/Crypto-Pyramid-Activity-Is-Banned-In-Iran' element={<React.Suspense><News6 /></React.Suspense>} />
              <Route path='/News/Bitcoin-As-The-13th-Largest-Asset-By-Market' element={<React.Suspense><News7 /></React.Suspense>} />
              <Route path='/News/Sorin-Web-Developers-CO-Launched-4th-Version-Of-CryptoCommerce-Exchange' element={<React.Suspense><News8 /></React.Suspense>} />
              <Route path='/News/Crypto-Loan-Service-Is-Launched-In-Cryptocommerce-Exchange' element={<React.Suspense><News9 /></React.Suspense>} />
              <Route path='/News/Binance-Decide-To-End-Of-Support-BUSD' element={<React.Suspense><News10 /></React.Suspense>} />
              <Route path='/News/Black-Rock-Release-Fake-News-About-ETF-Accept' element={<React.Suspense><News11 /></React.Suspense>} />
              <Route path='/News/Binance-Block-Over-120.000-Palestinian-Account' element={<React.Suspense><News12 /></React.Suspense>} />
              <Route path='/News/Merging_Bep2_Network_With_Bep20' element={<React.Suspense><News13 /></React.Suspense>} />
              <Route path='/News/Crypto_Commerce_Will_Delist_XMR_ANT_VAI_And_Multi' element={<React.Suspense><News14 /></React.Suspense>} />
              <Route path='/News/BTC_Touched_70K' element={<React.Suspense><News15 /></React.Suspense>} />
              <Route path='/News/bitcoin_halving_2024' element={<React.Suspense><News16 /></React.Suspense>} />
              <Route path='/News/Notcoin(NOT)_was_listed_in_the_best_iranian_exchange_cryptocommerce' element={<React.Suspense><News17 /></React.Suspense>} />
              <Route path='/News/Cryptocommerce_Support_That_Merging_Of_Fet_Agix_And_Ocean_To_Asi' element={<React.Suspense><News18 /></React.Suspense>} />
              <Route path='/News/CryptoCommerce-will-delisted-LOOM-REEF-VGX-FOR-EPX-CVP' element={<React.Suspense><News19 /></React.Suspense>} />
              <Route path='/News/CryptoCommerce-is-already-added-telegram-DOGS-token' element={<React.Suspense><News20 /></React.Suspense>} />
              <Route path='/News/Hamster-Kombat-Daily-Cipher-Code-9-September-in-CryptoCommerce' element={<React.Suspense><News21 /></React.Suspense>} />
              <Route path='/News/Hamster-Kombat-Daily-Combo-8-September-in-CryptoCommerce' element={<React.Suspense><News22 /></React.Suspense>} />
              <Route path='/News/Hamster-Kombat-Daily-Cipher-Code-14-September-in-CryptoCommerce' element={<React.Suspense><News23 /></React.Suspense>} />
              <Route path='/News/Hamster-Kombat-Daily-Combo-13-September-in-CryptoCommerce' element={<React.Suspense><News24 /></React.Suspense>} />
              <Route path='/News/Hamster-Kombat-Daily-Combo-14-September-in-CryptoCommerce' element={<React.Suspense><News25 /></React.Suspense>} />
              <Route path='/News/CryptoCommerce-will-be-list-HMSTR-asap' element={<React.Suspense><News26 /></React.Suspense>} />
              <Route path='/News/Hamster-Kombat-Daily-Cipher-Code-15-September' element={<React.Suspense><News27 /></React.Suspense>} />
              <Route path='/News/Hamster-Kombat-Daily-Combo-15-September' element={<React.Suspense><News28 /></React.Suspense>} />
              <Route path='/News/Hamster-Kombat-Daily-Cipher-Code-16-September' element={<React.Suspense><News29 /></React.Suspense>} />
              <Route path='/News/CryptoCommerce-support-migration-of-MATIC-to-POL-in-Polygon-blockchain' element={<React.Suspense><News30 /></React.Suspense>} />
              <Route path='/News/Hamster-Kombat-Daily-Combo-16-September' element={<React.Suspense><News31 /></React.Suspense>} />
              <Route path='/News/Hamster-Kombat-Daily-Cipher-Code-17-September' element={<React.Suspense><News32 /></React.Suspense>} />
              <Route path='/News/Hamster-Kombat-Daily-Combo-17-September' element={<React.Suspense><News33 /></React.Suspense>} />
              <Route path='/News/Hamster-Kombat-Daily-Cipher-Code-18-September' element={<React.Suspense><News34 /></React.Suspense>} />
              <Route path='/News/Hamster-Kombat-Daily-Combo-18-September' element={<React.Suspense><News35 /></React.Suspense>} />
              <Route path='/News/CryptoCommerce-already-listed-Hamster-Kombat-HMSTR' element={<React.Suspense><News36 /></React.Suspense>} />
              <Route path='/News/CryptoCommerce-added-EURI-SLF-NEIRO-TURBO-EIGEN-SCR-LUMIA' element={<React.Suspense><News37 /></React.Suspense>} />
              <Route path='/News/Bitcoin-Hitting-the-ATH-Price-And-Now-Trade-In-90.000-Dollar' element={<React.Suspense><News38 /></React.Suspense>} />
              <Route path='/News/CryptoCommerce-Echange-added-KAIA-COW-USUAL-CETUS-PMUT-ACT' element={<React.Suspense><News39 /></React.Suspense>} />
              <Route path='/News/CryptoCommerce-will-delisted-GFT-IRIS-OAX-KEY-REN' element={<React.Suspense><News40 /></React.Suspense>} />
              <Route path='/News/CryptoCommerce-will-delisted-AKRO-BLZ-And-WRX' element={<React.Suspense><News41 /></React.Suspense>} />
              <Route path='/News/CryptoCommerce-Exchange-added-THE-ACX-ORCA-MOVE-ME' element={<React.Suspense><News42 /></React.Suspense>} />
              <Route path='/News/CryptoCommerce-Echange-added-VELODROME-VANA-BIO-1000CAT-PENGU' element={<React.Suspense><News43 /></React.Suspense>} />
              <Route path='/News/CryptoCommerce-Echange-added-AIXBT-D-S-CGPT-COOKIE' element={<React.Suspense><News44 /></React.Suspense>} />
              <Route path='/News/CryptoCommerce-Echange-Supported-Buying-Gold-Online' element={<React.Suspense><News45 /></React.Suspense>} />
              <Route path='/News/CryptoCommerce-will-delisted-AMB-CLV-STMX-And-VITE' element={<React.Suspense><News46 /></React.Suspense>} />
              <Route path='/News/CryptoCommerce-Exchange-added-SOLV-TRUMP-CHEEMS-ANIME-BERA' element={<React.Suspense><News47 /></React.Suspense>} />
              <Route path='/News/CryptoCommerce-Exchange-added-TST-HEI-LAYER-KAITO' element={<React.Suspense><News48 /></React.Suspense>} />

              <Route path="/*" element={<NotFound />} />
            </Routes>
            <AlertMessage open={alert.alertCheck} status={alert.status} message={alert.message} duration={6000} close={handleClose} severity={alert.severity} page={"App"} />
          </CryptoContextProvider>
        </AllBookTickerContext>
      </ThemeProvider>
    </BrowserRouter >

  );
}

export default App;