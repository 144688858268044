import { Button, Grid, Modal, TextField, Typography, InputAdornment, CircularProgress } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import BitcoinImage from "../image/forTest/bitcoin.png";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import "../style/tradeBox.css";
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import { onlyFloatnaumber, separator } from "./modules/general";
import { stringValue, replaceNumber } from "./modules/general";
import axios from "axios";
import AlertMessage from './modules/AlertMessage';
import { baseUrl } from "../component/modules/general";
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from "react-router-dom";
import iconInventory from '../image/tradebox/iconInventory.png'
import loadingGif from '../image/loading/loading.gif';
import { infoCoinContext } from "../context/CryptoContextProvider";

let coinSelect = '';

export default function TradeBox({ authCheck, infoUser }) {
  const [alert, setAlert] = useState({ alertCheck: false, status: null, message: "", severity: "" });
  const [openModal, setOpenModal] = useState(false);
  const [typeOfOrder, setTypeOfOrder] = useState("buy");
  const [coinSelected, setCoinSelected] = useState({ coin: "BTC", base64: BitcoinImage });
  const [priceAsset, setPriceAsset] = useState([]);
  const [search, setSearch] = useState("");
  const [infoCoinData, setInfoCoinData] = useState([]);
  const [inputCryptoNumber, setInputCryptoNumber] = useState("")
  const [inputRialAmount, setInputRialAmount] = useState("")
  const [auth, setAuth] = useState(false);
  const [inventoryCrypto, setInventoryCrypto] = useState([])
  const { t, i18n } = useTranslation();
  const [symbolInfo, setSymbolInfo] = useState([]);
  const [btnStore, setBtnStore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nerkhArz, setNerkhArz] = useState(0);
  const Navigate = useNavigate();
  const infoCoin = useContext(infoCoinContext);

  useEffect(() => {
    if (auth === true) {
      getInventoryCrypto(coinSelected);
      const intervalInventory = setInterval(() => {
        getInventoryCrypto(coinSelected);
      }, 30000);
      return () => {
        clearInterval(intervalInventory)
      };
    }
  }, [auth, coinSelected])

  useEffect(() => {
    setAuth(authCheck);
    return () => {
    }
  }, [authCheck])

  useEffect(() => {

  }, [inventoryCrypto])

  useEffect(() => {
    infoCoin && setInfoCoinData(infoCoin);
  }, [infoCoin])

  useEffect(() => {
    getNerkhArz();
  }, [])

  useEffect(() => {
    coinSelect = coinSelected;
  }, [coinSelected])


  useEffect(() => {
    getPriceAseet(coinSelected);
    getExchangeInfo();
    const intervalPrice = setInterval(() => {
      getPriceAseet(coinSelect)
    }, 377000);
    return () => {
      clearInterval(intervalPrice)
    };
  }, [])

  //get inventoryCrypto Api
  const getInventoryCrypto = (asset) => {
    const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
    Promise.resolve(axios({
      url: baseUrl + "/api/v4/user/inventoryCrypto?asset=" + asset.coin,
      method: "GET",
      headers: { 'Authorization': AuthStr },
    }))
      .then((res) => {
        setInventoryCrypto(res.data.data);
      })
      .catch(
        (error) => {
          if (error.code === "ERR_NETWORK") {
            setAlert({ alertCheck: true, message: t("networkNotconnected"), severity: "error" })
          }
          else {
            const { status } = error.response;
            if ((status > 500) || (status < 600)) {
              const timeout = setTimeout(() => {
                return Promise.reject(error)
              }, 15000)
              return () => {
                clearTimeout(timeout)
              };
            } else {
              setAlert({ alertCheck: true, status: status, message: "", severity: "error" })
            }
          }
        }
      )
  }

  const getExchangeInfo = () => {
    Promise.resolve(axios({
      url: baseUrl + "/api/v4/general/exchangeSymbolInfo",
      method: "GET",
    }))
      .then((res) => {
        setSymbolInfo(res.data.data);
        funcMarket(coinSelected);
      })
  }


  //get priceAsset Api
  function getPriceAseet(data) {
    if (data.coin !== coinSelect.coin) {
      funcMarket(data);
    }
    setCoinSelected({ coin: data.coin, base64: data.base64 });
    Promise.resolve(axios({
      url: baseUrl + "/api/v4/general/getPriceAsset?asset=" + data.coin,
      method: "GET",
    }))
      .then((res) => {
        setPriceAsset(res.data.data);
      })
      .catch(
        (error) => {
          if (error.code === "ERR_NETWORK") {
            setAlert({ alertCheck: true, message: t("networkNotconnected"), severity: "error" })
          }
          else {
            const { status } = error.response;
            setAlert({ alertCheck: true, status: status, message: "", severity: "error" })
            if (status === 400) {
              setCoinSelected({ coin: "BTC", base64: BitcoinImage })
            }
            if (status === 404) {
              setCoinSelected({ coin: "BTC", base64: BitcoinImage })
            }
            if ((status > 500) || (status < 600)) {
              const timeout = setTimeout(() => {
                return Promise.reject(error)
              }, 15000)
              return () => {
                clearTimeout(timeout)
              };
            }
          }
        }
      )
  }

  const getNerkhArz = () => {
    Promise.resolve(axios({
      url: baseUrl + "/api/v4/general/nerkheArz",
      method: "GET",
    }))
      .then((res) => {
        return setNerkhArz(res.data.data[0].CurrencyLastValue);
      })
      .catch((error) => {
        if (error.code === "ERR_NETWORK") {
          setAlert({ alertCheck: true, message: t("networkNotconnected"), severity: "error" })
        }
        else {
          const { status } = error.response;
          setAlert({ alertCheck: true, status: status, message: "", severity: "error" })
        }
      })
  }

  const funcMarket = (data) => {
    let market = [];
    let quote = ['USDT', 'BTC', 'BNB', 'ETH'];
    let quoteFind = 'USDT';
    symbolInfo !== undefined &&
      symbolInfo.forEach(item => {
        if (item.baseAsset === data.coin) {
          market.push(item.quoteAsset);
        }
      })
    let findQuote = false;
    quote.forEach((element) => {
      market.forEach((element2) => {
        if (element === element2 && !findQuote) {
          findQuote = true;
          quoteFind = element;
        }
      })
    })
  }
  const setDecimal = (num, decimal) => {
    var hun = 1;
    var Deci = null;
    for (let i = 0; i < decimal; i++) {
      hun *= 10;
    }
    if (num > 0) {
      Deci = Math.floor(num * hun) / hun;
    }
    else {
      Deci = Math.ceil(num * hun) / hun;
    }
    return Deci;
  }

  const storeHandler = () => {
    let quantity;
    let stepAmount;
    if (auth === false) {
      Navigate('/Login')
    }
    else if (infoUser) {
      if (infoUser.userLevel === 1) {
        typeOfOrder === "buy" ? setAlert({ alertCheck: true, message: t("AM_buylevelUserOne"), severity: "error" }) :
          setAlert({ alertCheck: true, message: t("AM_selllLevelUserOne"), severity: "error" })
      }
      else {
        if (priceAsset.hasOwnProperty("step")) {
          let step = String(priceAsset.step).indexOf("1");
          stepAmount = parseFloat(step) - 1;
        }
        quantity = setDecimal(inputCryptoNumber, stepAmount);
        if ((quantity === "") && (inputRialAmount === "")) {
          setAlert({ alertCheck: true, message: t("AM_NullTradeBox"), severity: "error" })
        }
        else if (quantity === "") {
          setAlert({ alertCheck: true, message: t("AM_NotCrypto"), severity: "error" })
        }
        else if (inputRialAmount === "") {
          setAlert({ alertCheck: true, message: t("AM_NotRial"), severity: "error" })
        }
        else {
          const rialAmount = Math.floor(parseInt(inputRialAmount) / nerkhArz);
          const nerkh = separator(10 * nerkhArz);
          setBtnStore(true);
          setLoading(true);
          if ((infoUser.userLevel === 2) && (rialAmount !== 10)) {
            typeOfOrder === "buy" ? setAlert({ alertCheck: true, message: t("AM_buylevelUser2Part1") + nerkh + t("AM_buylevelUser2Part2"), severity: "error" }) :
              setAlert({ alertCheck: true, message: t("AM_sellLevelUser2Part1") + nerkh + t("AM_sellLevelUser2Part2"), severity: "error" })
            setBtnStore(false);
            setLoading(false);
          }
          else if ((infoUser.userLevel >= 3) && (rialAmount < 10)) {
            typeOfOrder === "buy" ? setAlert({ alertCheck: true, message: t("AM_buylevelUser3part1") + nerkh + t("AM_buylevelUser3part2"), severity: "error" }) :
              setAlert({ alertCheck: true, message: t("AM_sellLevelUser3part1") + nerkh + t("AM_sellLevelUser3part2"), severity: "error" })
            setBtnStore(false);
            setLoading(false);
          }
          else {
            if ((typeOfOrder === "sell") && (inventoryCrypto.coin < quantity)) {
              setAlert({ alertCheck: true, message: t("AM_noinventoryCoin"), severity: "error" })
              setBtnStore(false);
              setLoading(false);
            }
            else if ((typeOfOrder === "buy") && (inventoryCrypto.IRR < parseInt(inputRialAmount))) {
              setAlert({ alertCheck: true, message: t("AM_NoInventoryRial"), severity: "error" })
              setBtnStore(false);
              setLoading(false);
            }
            else {
              let data = { baseAsset: coinSelect.coin, quantity: quantity, amount: parseInt(inputRialAmount) };
              let url = typeOfOrder === "sell" ? "/api/v4/store/sell" : "/api/v4/store/buy";
              const AuthStr = 'bearer ' + (localStorage.getItem('TACC'));
              Promise.resolve(axios({
                url: baseUrl + url,
                method: "POST",
                data: data,
                headers: { 'Authorization': AuthStr },
              }))
                .then((res) => {
                  setLoading(false);
                  setBtnStore(false);
                  getInventoryCrypto(coinSelected);
                  setInputCryptoNumber("");
                  setInputRialAmount("");
                  setAlert({ alertCheck: true, message: t("AM_done"), severity: "success" });
                })
                .catch(
                  (error) => {
                    setLoading(false);
                    setBtnStore(false);
                    setInputCryptoNumber("");
                    setInputRialAmount("");
                    if (error.code === "ERR_NETWORK") {
                      setAlert({ alertCheck: true, message: t("networkNotconnected"), severity: "error" })
                    }
                    else {
                      const { status } = error.response;
                      setAlert({ alertCheck: true, status: status, message: "", severity: "error" })
                    }
                  }
                )
            }
          }
        }
      }
    }
  }

  const onCloseModal = () => {
    setOpenModal(false);
    setSearch("");
    setInfoCoinData(infoCoin);
    setInputCryptoNumber("");
    setInputRialAmount("");
  }

  const onClickModal = () => {
    setOpenModal(true);
  }

  const onClickBuyOrder = () => {
    setTypeOfOrder("buy");
    setInputCryptoNumber("");
    setInputRialAmount("");
  }

  const onClickSellOrder = () => {
    setTypeOfOrder("sell");
    setInputCryptoNumber("");
    setInputRialAmount("");
  }

  //changeHandeler input
  const searchHandeler = event => {
    event.target.value = event.target.value.toUpperCase();
    setSearch(event.target.value);
    setInfoCoinData(infoCoin.filter(item => item.coin.toUpperCase().includes(event.target.value.toUpperCase())))
  }

  const changeHandelerRial = event => {
    let value = onlyFloatnaumber(event.target.value)
    setInputRialAmount(value)
    changeOfRial(value);
  }

  const changeHandelerCryptoNumber = event => {
    let value = onlyFloatnaumber(event.target.value)
    setInputCryptoNumber(value);
    changeOfCrypto(value);
  }

  const totalRialHandler = () => {
    setInputRialAmount((inventoryCrypto.IRR).toString());
    changeOfRial(inventoryCrypto.IRR)
  }

  const totalCoinHandler = () => {
    setInputCryptoNumber((inventoryCrypto.coin).toString());
    changeOfCrypto(inventoryCrypto.coin)
  }

  //convert crypto to Rial 
  const changeOfCrypto = (value) => {
    if (parseFloat(value) > 0) {
      if (typeOfOrder === "buy") {
        setInputRialAmount((((priceAsset.sell_price) * (stringValue(value)))).toString())
      }
      if (typeOfOrder === "sell") {
        setInputRialAmount(((priceAsset.buy_price) * (stringValue(value))).toString())
      }
    }
    else {
      setInputRialAmount('')
    }
  }

  //convert Rial to crypto 
  const changeOfRial = (value) => {
    if (parseFloat(value) > 0) {
      if (typeOfOrder === "buy") {
        setInputCryptoNumber(parseFloat((stringValue(value) / (priceAsset.sell_price)).toFixed(8)).toString());
      }
      if (typeOfOrder === "sell") {
        setInputCryptoNumber(parseFloat((stringValue(value) / (priceAsset.buy_price)).toFixed(8)).toString());
      }
    }
    else {
      setInputCryptoNumber("");
    }
  }

  //create list coin for Modal
  const createListCoin = () => {
    let list = [];
    infoCoinData.forEach((item) => {
      list.push(
        <Grid key={item.id} onClick={() => getPriceAseet(item)}
          sx={{
            display: "flex", flexDirection: 'row', alignItems: "center", justifyContent: "space-between", padding: "10px",
            margin: "10px 0px", backgroundColor: 'background.paper', width: "100%", borderRadius: "10px", cursor: "pointer"
          }}>
          <Typography component={"p"}>{item.coin}</Typography>
          <Typography component={"img"} alt={item.coin} src={item.base64} sx={{ width: "40px", height: "40px" }} />
        </Grid>
      )
    })
    return list;
  }

  //landle close Alert
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    return setAlert({ alertCheck: false, message: "", severity: alert.severity })
  };

  return (
    <>
      <Grid
        sx={{
          width: { xs: "100%", sm: "300px", md: "381px" }, height: auth ? { xs: "100%", sm: "450px", md: "500px" } : { xs: "100%", sm: "400px", md: "473px" }, borderRadius: { xs: "0px", sm: "33px", md: "44px" }, display: "flex", alignItems: "center", justifyContent: "center",
          padding: { xs: "15px 5px", sm: "20px" }, flexDirection: "column", backgroundColor: "background.main", direction: i18n.language === "fa" ? "rtl" : "ltr"
          , boxShadow: { xs: "", sm: "0px 1px 8px rgba(15, 15, 15, 0.15)" }
        }}>
        <Button sx={{
          backgroundColor: "background.main", width: { xs: "40%", sm: "150px", md: "190px" }, height: { xs: "40px", sm: "38px", md: "46px" }, "&:hover": { backgroundColor: "background.main" }, color: "text.primary", borderRadius: "38px",
          border: "1px solid #B9B9B9", margin: "10px 0 ", filter: "drop-shadow(1px 1px 3px rgba(83, 83, 83, 0.25))"
        }}
          onClick={onClickModal}>
          <Typography component={"img"} alt={coinSelected.coin} src={coinSelected.base64} sx={{ width: { xs: "26px", sm: "20px", md: "26px" }, height: { xs: "26px", sm: '20px', md: "26px" } }} />
          <Grid sx={{ width: "5px" }}></Grid>
          <Typography sx={{ fontSize: { xs: "16px", sm: "16px", md: "18px" } }}>
            {coinSelected.coin}
          </Typography>
          <KeyboardArrowDownRoundedIcon sx={{ fontSize: "25px", color: "text.primary" }} />
        </Button>
        <Grid sx={{
          backgroundColor: "background.main", width: { xs: "80%", sm: "230px", md: "313px" }, border: " 0.7px solid #B9B9B9", height: { xs: "50px", sm: "45px", md: "56px" },
          borderRadius: { xs: "8px", sm: "10px", md: "12px" }, margin: "8px 20px", display: "flex", alignItems: "center"
        }}>
          <Grid onClick={onClickSellOrder}
            sx={{
              flexGrow: "1", borderRadius: { xs: "8px", sm: "10px", md: "12px" }, backgroundColor: typeOfOrder === "sell" ? "#E63B2E" : "background.main", height: { xs: "40px", sm: "35px", md: "47px" }, display: "flex", alignItems: "center", justifyContent: "center",
              cursor: "pointer", transition: "0.3s all", maxWidth: { xs: "50%", sm: "115px", md: "149px" }, margin: "2px"
            }}
          >
            <Typography component={"p"} sx={{ fontSize: { xs: "16px", sm: "14px", md: "18px" }, color: typeOfOrder === "sell" ? "#F7F7FA" : "text.primary", transition: "0.3s all", userSelect: "none" }}>
              {t("sell")}
            </Typography>
          </Grid>
          <Grid sx={{ width: "5px" }}></Grid>
          <Grid onClick={onClickBuyOrder}
            sx={{
              flexGrow: "1", borderRadius: { xs: "8px", sm: "10px", md: "12px" }, backgroundColor: typeOfOrder === "buy" ? "#399974" : "background.main", height: { xs: "40px", sm: "35px", md: "47px" }, display: "flex", alignItems: "center", justifyContent: "center",
              cursor: "pointer", transition: "0.3s all", boxShadow: typeOfOrder === "buy" ? "0px 1px 7px #04040438" : "none", maxWidth: { xs: "50%", sm: "115px", md: "149px" }, margin: "2px"
            }}
          >
            <Typography component={"p"} sx={{ fontSize: { xs: "16px", sm: "14px", md: "18px" }, color: typeOfOrder === "buy" ? "#F7F7FA" : "text.primary", transition: "0.3s all", userSelect: "none" }}>
              {t("buy")}
            </Typography>
          </Grid>
        </Grid>
        <Grid sx={{ padding: "8px", height: { xs: "50px", md: "60px" }, display: "flex", width: "100%", }}>
          <Grid sx={{ flexGrow: "1", height: "100%", display: "flex", alignItems: "center", justifyContent: "space-evenly", flexDirection: "row" }}>
            <Typography component={"p"} sx={{ color: typeOfOrder === "buy" ? "#2D936C" : "#E63B2E", fontSize: { xs: "14px", sm: "12px", md: "15px" } }}>
              {typeOfOrder === "sell" ? t("sellPrice") : t("buyPrice")}
            </Typography>
            <Typography component={"p"} sx={{ color: typeOfOrder === "buy" ? "#2D936C" : "#E63B2E", fontSize: { xs: "14px", sm: "12px", md: "15px" } }} >
              {typeOfOrder === "buy" ? priceAsset.hasOwnProperty('sell_price') ? separator(priceAsset.sell_price) : null : priceAsset.hasOwnProperty('buy_price') ? separator(priceAsset.buy_price) : null}
            </Typography>
          </Grid>
        </Grid>
        <TextField
          className="textField"
          fullWidth
          onChange={changeHandelerCryptoNumber}
          label={t("cryptoNumber")}
          InputLabelProps={{ sx: { fontSize: { xs: "12px", md: "1rem" } } }}
          value={replaceNumber(separator(inputCryptoNumber), i18n.language)}
          sx={{
            margin: "10px 0", maxWidth: { xs: "80%", sm: "230px", md: "313px" }, "& .MuiInputBase-root fieldset": { borderRadius: { xs: "10px", sm: "8px", md: "16px" } }
            , "& .MuiOutlinedInput-input": { padding: { xs: "15px", sm: '10px', md: "15px" } }
          }}
          InputProps={{
            endAdornment:
              <Grid onClick={totalCoinHandler} sx={{ display: auth ? (typeOfOrder === "buy") ? "none" : "flex" : "none", alignItems: "center", justifyContent: 'center', backgroundColor: "background.blueLight", borderRadius: "11px", margin: "0px 5px", cursor: "pointer", width: "60px", height: "28px" }}>
                <Typography component={"img"} src={iconInventory} alt="icon"
                  sx={{ width: "26px", height: "26px", }} />
              </Grid>
          }}
        />
        <TextField
          className="textField"
          fullWidth
          onChange={changeHandelerRial}
          label={t("rialAmount")}
          InputLabelProps={{ sx: { fontSize: { xs: "12px", md: "1rem" } } }}
          value={replaceNumber(separator(inputRialAmount), i18n.language)}
          sx={{
            margin: "10px 0", maxWidth: { xs: "80%", sm: "230px", md: "313px" }, "& .MuiInputBase-root fieldset": { borderRadius: { xs: "10px", sm: "8px", md: "16px" } }
            , "& .MuiOutlinedInput-input": { padding: { xs: "15px", sm: '10px', md: "15px" } }
          }}
          InputProps={{
            endAdornment:
              <Grid onClick={totalRialHandler} sx={{ display: auth ? (typeOfOrder === "sell") ? "none" : "flex" : "none", alignItems: "center", justifyContent: 'center', backgroundColor: "background.blueLight", borderRadius: "11px", margin: "0px 5px", cursor: "pointer", width: "60px", height: "28px" }}>
                <Typography component={"img"} src={iconInventory} alt="icon"
                  sx={{ width: "26px", height: "26px", }} />
              </Grid>
          }}
        />
        <Grid sx={{ width: { xs: "75%", sm: "85%" }, display: auth === false ? "none" : "flex", flexDirection: "column", margin: "5px 0" }}>
          <Typography sx={{ marginBottom: "5px", fontSize: { xs: "13px", md: "16px" } }}>{t("yourInventory")}</Typography>
          <Grid sx={{
            display: "flex", justifyContent: "space-evenly", width: "100%", padding: "10px",
          }}>
            <Typography
              sx={{ fontSize: i18n.language === "fa" ? "15px" : "14px", display: "flex", justifyContent: "start", marginLeft: "2px", width: "50%" }}>
              {typeOfOrder === "buy" ? t("inventoryRial") : t("inventoryCrypto")}:
            </Typography>
            <Typography
              sx={{ fontSize: i18n.language === "fa" ? "15px" : "14px", display: "flex", justifyContent: "end", color: typeOfOrder === "buy" ? "#2D936C" : "rgb(224, 38, 38)", width: "50%" }}>
              {inventoryCrypto.length === 0 ? "0" : typeOfOrder === "buy" ? separator(parseInt(inventoryCrypto.IRR).toFixed(0)) : (separator(+inventoryCrypto.coin))}
            </Typography>
          </Grid>
        </Grid>
        <Button onClick={storeHandler}
          sx={{
            width: { xs: "60%", sm: "210px", md: "270px" }, height: { xs: "50px", sm: "50px", md: "60px" }, background: (theme) => theme.palette.button.secondary,
            "&:hover": { background: (theme) => theme.palette.button.secondaryHover }, borderRadius: "40px", margin: auth ? "0" : "10px 0", boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.25)"
          }}
          disabled={btnStore === true ? true : false} >
          <Typography sx={{ fontSize: { xs: "14px", md: "20px" }, color: "text.primary" }}>
            {btnStore === true && loading === true ? <CircularProgress size={30} color="inherit" sx={{ color: "text.white" }} /> : auth === true ? typeOfOrder === "buy" ? t("buy") : t("sell") : typeOfOrder === "buy" ? t("loginBuy") : t("loginSell")}
          </Typography>
        </Button>
      </Grid>
      <Modal
        open={openModal}
        onClose={onCloseModal}
      >
        <Grid sx={{ width: "300px", height: "380px", backgroundColor: "background.modalTradeBox", padding: "10px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", borderRadius: "20px", overflow: "auto" }}>
          <Grid sx={{ display: "flex", justifyContent: "space-between", direction: i18n.language === "fa" ? "rtl" : "ltr", margin: " 5px 15px 10px 15px", fontWeight: "bold" }}>
            <Typography component={"p"}>{t("listCrypto")}</Typography>
            <ClearIcon sx={{ width: "20px", cursor: "pointer", "&:hover": { color: "text.orange" } }}
              // onClick={() => setOpenModal(false)}
              onClick={() => onCloseModal()}
            />
          </Grid>
          <Grid sx={{ padding: "0px 15px" }}>
            <Grid sx={{ height: "50px", display: "flex", width: "100%", backgroundColor: "background.secondary", borderRadius: "10px" }}>
              <TextField className="text_field" fullWidth sx={{ textAlign: "center" }} label={t("cryptoSearch")} variant="outlined"
                value={search}
                onChange={searchHandeler}
                InputLabelProps={{ sx: { fontSize: i18n.language === 'fa' ? "14px" : "12px" } }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              sx={{ width: '100%', position: 'relative', overflow: 'auto', borderRadius: "10px" }}
              onClick={() => onCloseModal()}>
              {infoCoinData === null ?
                <Typography component={'img'} src={loadingGif} style={{ width: '30%', margin: "10% 35%" }} alt="load"></Typography>
                :
                createListCoin()}
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <AlertMessage open={alert.alertCheck} status={alert.status} message={alert.message} duration={6000} close={handleClose} severity={alert.severity} page={"tradeBox"} />
    </>
  );
};